<template>
    <div> 
        <div class="w-full bg-white z-30 pb-10 md:pb-0">
            <div class="flex md:justify-between items-center">
                <div>
                    <button class="lg:hidden block" @click="showList = !showList">
                        <svg class="w-5 lg:w-12 fixed z-50 ml-2 mt-10 md:mt-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg> 
                    </button>
                </div>

                <div class="flex justify-between md:justify-end items-center space-x-4 my-2 md:px-10">
                  <div class="lg:hidden block">
                       <router-link to="/">
                            <img src="images/jiipa.png" class="w-14">
                       </router-link>
                  </div>

                    <div class="flex items-center space-x-1 md:space-x-4 ">
                        <div class="w-16 md:w-36 border flex shadow-md rounded-full">
                            <a :href="sekaiIchi" target="_blank" class="p-0.5 rounded-full max-h-8 w-full">
                                 <img src="images/sekai-logo.webp" class="bg-white rounded-full max-h-full px-1 w-full object-contain border hover:border-gray-400">
                            </a>
                        </div>
                       
                        <div class="w-16 md:w-36 border flex shadow-md rounded-full relative">
                            <a :href="recruitUrl" target="_blank" class="group relative p-0.5 rounded-full max-h-8 w-full">
                                <img src="images/smartRecruit.jpg" class="bg-white rounded-full max-h-full px-1 w-full object-contain border hover:border-gray-400">
                                <!-- <div class="opacity-0 w-28 bg-black text-white text-center text-xs rounded-lg py-2 -top-1 absolute z-10 group-hover:opacity-100">
                                    coming soon...{{$t('coming-soon')}}
                                    <svg class="absolute inset-x-12 flex justify-center items-center text-center text-black h-2 w-2 top-full" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
                                </div> -->
                            </a>
                        </div>
                        <!-- <div class="relative inline-flex shadow-md rounded-full p-0.5 border">
                            <svg class="w-1 md:w-2 absolute top-0 right-0 m-2 md:m-3 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#000" fill-rule="nonzero"/></svg>
                            <select class="md:w-36 w-16 border rounded-full pl-2 md:pl-4 pr-2 md:pr-8 md:p-1 font-semibold text-small md:text-xs hover:border-gray-400 focus:outline-none appearance-none" @change="languageHandler(), getWebLang()" v-model="locale">
                             <option :value="language.slug" v-for="(language, index) in this.languageData" :key="language.id">{{ language.title }}</option>
                            </select>
                        </div>  -->
                        
                        <!-- <div class="bg-white flex shadow-md rounded-full p-0.5 border my-auto">
                            <a :href="jiipaClientUrl" class="bg-greenBg hover:bg-green-800 flex justify-center py-2 rounded-full text-white w-14 md:w-32 lg:w-48 uppercase text-small md:text-xs my-auto font-semibold">JIIPA</a>
                        </div> -->
                   
                   <div class="flex hidden space-x-1" v-if="user.authenticated">
                        <div class="my-auto">
                            <span>
                                <svg class="fill-current text-white p-1 bg-red-600 hover:bg-red-700 rounded-full" xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24" width="30px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"/></svg>
                            </span>
                        </div>
                        <div class="flex items-center">
                            <button @click="dropDownProfile = !dropDownProfile">
                                <img v-if="$store.getters['auth/user']['data'].profileImageUrl != '' || $store.getters['auth/user']['data'].profileImageUrl != undefined" :src="$store.getters['auth/user']['data'].profileImageUrl" class="w-8 h-8 border rounded-full object-cover" style="width:1.9rem; height:1.9rem;">
                                <img v-else src="images/user1.webp" class="w-8 h-8 border rounded-full object-cover" style="width:1.9rem; height:1.9rem;">
                            </button>
                            <button v-if="dropDownProfile" @click="dropDownProfile = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-black opacity-0 cursor-default z-50"></button>

                            <!-- profile dropdown -->
                            <div class="relative z-40" v-if="dropDownProfile">
                                <ul class="absolute -right-2 top-9 w-36 bg-white space-y-1 text-sm rounded-md shadow-lg">
                                    <li class="hover:bg-gray-200 pl-4 py-2 rounded-t-md">
                                        <router-link to="my-profile">{{$t('CADL-h3')}}<!--  My Profie --></router-link>
                                    </li>
                                    <li class="hover:bg-gray-200 pl-4 py-2">
                                        <router-link to="my-profile"><!--  Dashboard  -->{{$t('dashboard')}}</router-link>
                                    </li>
                                    <li class="hover:bg-gray-200 pl-4 py-2">
                                        <router-link to="change-password">{{$t('EUCP-h1')}} <!-- Change Password --> </router-link>
                                    </li>
                                    <li class="px-3"><hr>
                                    </li>
                                    <li class="hover:bg-gray-200 pl-4 py-3 rounded-b-md font-semibold text-yellow-700">
                                        <router-link to="#" v-on:click="logout">{{$t('esi-txt2')}} <!-- Logout --> </router-link>
                                    </li>
                                </ul>
                            </div>                        
                        </div>
                    </div>
                    </div>
            </div>  
        </div>          

            <header class="hidden lg:block" id="header-sticky">
                <div class=" border-b relative">
                    <img src="images/header_background.webp" class="w-full h-full">
                    <div class="absolute inset-0 flex justify-between items-center px-10">
                        <div class="my-auto">
                            <router-link to="/">
                                <img src="images/jiipa.png" class="w-14 xl:w-full">
                            </router-link>
                        </div>
                        <div class="flex space-y-2">          
                            <div class="flex items-center space-x-6 xl:space-x-8 text-xs xl:text-sm">
                                <!-- <router-link  @click="closeDeleteModel" v-for="link in headerLinks" :to="link.link"  class="menu_active uppercase text-gray-800 font-bold hover:text-green-600">
                                    {{$t(link.name)}}
                                </router-link> -->
                                <router-link to="/" class="menu_active uppercase text-gray-800 font-bold hover:text-green-600">
                                        {{$t('eh-nb-title1')}}
                                    </router-link>
                                    <router-link to="/about-us" class="menu_active uppercase text-gray-800 font-bold hover:text-green-600">
                                        {{$t('eh-nb-title2')}}
                                    </router-link>
                                    <router-link to="/services" class="menu_active uppercase text-gray-800 font-bold hover:text-green-600">
                                        {{$t('CADL-h12')}}
                                    </router-link>
                                    <!-- <router-link to="/membership" class="menu_active hidden uppercase text-gray-800 font-bold hover:text-green-600">
                                        {{$t('eh-nb-title3')}}
                                    </router-link> -->
                                    <router-link to="/events" class="menu_active uppercase text-gray-800 font-bold hover:text-green-600">
                                        {{$t('eh-nb-title4')}}
                                    </router-link>
                                    <router-link to="/contact-us" class="menu_active uppercase text-gray-800 font-bold hover:text-green-600">
                                        {{$t('eh-nb-title5')}}
                                    </router-link>
                                    <router-link to="/newsletter" class="menu_active uppercase text-gray-800 font-bold hover:text-green-600">
                                        {{$t('CADL-h14')}}
                                    </router-link>
                                    <router-link to="/gallery" class="menu_active uppercase text-gray-800 font-bold hover:text-green-600">
                                        {{$t('eh-nb-title6')}}
                                    </router-link>
                                <div class="hidden">
                                    <div class="flex space-x-3" v-if="user.authenticated">
                                        <div class="my-auto">
                                            <span>
                                                <svg class="fill-current text-white p-1 bg-red-600 hover:bg-red-700 rounded-full" xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24" width="30px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"/></svg>
                                            </span>
                                        </div>
                                        <div class="flex items-center">
                                            <button @click="dropDownProfile = !dropDownProfile">
                                                <img v-if="$store.getters['auth/user']['data'].profileImageUrl != '' || $store.getters['auth/user']['data'].profileImageUrl != undefined" :src="$store.getters['auth/user']['data'].profileImageUrl" class="w-8 h-8 border rounded-full object-cover" style="width:1.9rem; height:1.9rem;">
                                                <img v-else src="images/user1.webp" class="w-8 h-8 border rounded-full object-cover" style="width:1.9rem; height:1.9rem;">
                                            </button>
                                            <button v-if="dropDownProfile" @click="dropDownProfile = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-black opacity-0 cursor-default z-50"></button>

                                            <!-- profile dropdown -->
                                            <div class="relative z-50" v-if="dropDownProfile">
                                                <ul class="absolute -right-2 top-9 w-36 bg-white space-y-1 text-sm rounded-md shadow-lg">
                                                    <li class="hover:bg-gray-200 pl-4 py-2 rounded-t-md"><router-link to="my-profile"> {{$t('CADL-h3')}}</router-link></li>
                                                    <li class="hover:bg-gray-200 pl-4 py-2"><router-link to="my-profile"> {{$t('CADL-h7')}} </router-link></li>
                                                    <li class="hover:bg-gray-200 pl-4 py-2"><router-link to="change-password"> {{$t('EUCP-h1')}} </router-link></li>
                                                    <li class="px-3"><hr></li>
                                                    <li class="hover:bg-gray-200 pl-4 py-3 rounded-b-md font-semibold text-yellow-700"><router-link to="#" v-on:click="logout"> {{$t('esi-txt2')}} </router-link></li>
                                                </ul>
                                            </div>
                                            <!--  -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
   <!-- ~~~~~~~~~~~~~~~~~~~~~~~responsive -->
      <!--   <header class="hidden">
            <div class="container mx-auto px-4 py-2 flex justify-between bg-gradient-to-r from-blue-100 to-blue-50">
                 <div class="">
                    <router-link to="/">
                        <img src="images/jiipa.png" class="">
                    </router-link>
                </div>      
            </div>
        </header> -->

  <!-- ~~~~~~~~~~~~~~~responsive header list -->
        <div class="container mx-auto">
            <transition name="slide">
                <section  v-if="showList" class="absolute right-0 inset-y-0 w-full h-full max-w-2xl z-50" > 
                    <div class="bg-white shadow-xl w-full max-w-2xl h-full fixed">
                        <div class="flex justify-between p-4">   
                            <div class="">
                                <div class="flex flex-col space-y-6">
                                    <!-- <router-link @click="closeDeleteModel" v-for="link in headerLinks" :to="link.link" class="menu_active text-gray-700 hover:text-green-600">
                                      {{$t(link.name)}}
                                    </router-link> -->
                                    <router-link @click="closeDeleteModel" to="/" class="menu_active uppercase text-gray-800 font-bold hover:text-green-600">
                                        {{$t('eh-nb-title1')}}
                                    </router-link>
                                    <router-link @click="closeDeleteModel" to="/about-us" class="menu_active uppercase text-gray-800 font-bold hover:text-green-600">
                                        {{$t('eh-nb-title2')}}
                                    </router-link>
                                    <router-link @click="closeDeleteModel" to="/services" class="menu_active uppercase text-gray-800 font-bold hover:text-green-600">
                                        {{$t('CADL-h12')}}
                                    </router-link>
                                    <!-- <router-link @click="closeDeleteModel" to="/membership" class="menu_active hidden uppercase text-gray-800 font-bold hover:text-green-600">
                                        {{$t('eh-nb-title3')}}
                                    </router-link> -->
                                    <router-link @click="closeDeleteModel" to="/events" class="menu_active uppercase text-gray-800 font-bold hover:text-green-600">
                                        {{$t('eh-nb-title4')}}
                                    </router-link>
                                    <router-link @click="closeDeleteModel" to="/contact-us" class="menu_active uppercase text-gray-800 font-bold hover:text-green-600">
                                        {{$t('eh-nb-title5')}}
                                    </router-link>
                                    <router-link @click="closeDeleteModel" to="/newsletter" class="menu_active uppercase text-gray-800 font-bold hover:text-green-600">
                                        {{$t('CADL-h14')}}
                                    </router-link>
                                    <router-link @click="closeDeleteModel" to="/gallery" class="menu_active uppercase text-gray-800 font-bold hover:text-green-600">
                                        {{$t('eh-nb-title6')}}
                                    </router-link>
                                    <div class="hidden flex items-center">
                                        <button @click="dropIt">
                                            <svg class="fill-current text-gray-500 p-1 bg-gray-200 hover:bg-gray-300 rounded-full border border-gray-300" xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24" width="30px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>
                                        </button>
                                        <!-- profile dropdown -->
                                        <div class="relative z-50">
                                            <ul class="absolute -right-2 top-9 w-36 bg-white space-y-1 text-sm rounded-md shadow-lg" v-if="isDropped">
                                                <li class="hover:bg-gray-200 pl-4 py-2 rounded-t-md"><router-link to="my-profile">{{$t('CADL-h3')}}</router-link></li>
                                                <li class="hover:bg-gray-200 pl-4 py-2"><router-link to="my-profile">{{$t('CADL-h7')}}</router-link></li>
                                                <li class="hover:bg-gray-200 pl-4 py-2"><router-link to="change-password">{{$t('EUCP-h1')}}</router-link></li>
                                                <li class="px-3"><hr></li>
                                                <li class="hover:bg-gray-200 pl-4 py-3 rounded-b-md font-semibold text-yellow-700"><router-link to="#" v-on:click="logout">{{$t('esi-txt2')}}</router-link></li>
                                            </ul>
                                        </div>
                                        <!--  -->
                                    </div>
                                </div>
                            </div>           
                            <div>
                                <button @click="closeDeleteModel"  class="inline-flex items-center transform hover:scale-125 hover:text-red-600 focus:outline-none"> 
                                   <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" /></svg>
                                </button>
                            </div>
                        </div>      
                    </div>
                </section>
            </transition>
        </div>
  <!-- ~~~~~~~~~~~~~~~~~~ -->
    </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import {mapMutations} from 'vuex';
import {mapState} from 'vuex';
import { isEmpty } from 'lodash'


export default {  
  data: function() {
    return {
        languageData:[],
        locale: 'en',
        jiipaClientUrl: process.env.VUE_APP_JIIPA_URL,
        recruitClientUrl: process.env.VUE_APP_SMART_RECRUIT_URL,
        sekaiIchi: process.env.VUE_APP_SEKAI_ICHI_URL + 'product.php',
        dropDownProfile: false,
        universalDrop:0,
        showList:false,
        /*headerLinks: [      
            {name: 'eh-nb-title1', link: '/'},
            {name: 'eh-nb-title2', link: 'about-us'},
            {name: 'CADL-h12', link: 'services'},
            {name: 'eh-nb-title3', link: 'membership'},
            {name: 'eh-nb-title4', link: 'events'},
            {name: 'eh-nb-title5', link: 'contact-us'},
            {name: 'CADL-h14', link: 'newsletter'},
            {name: 'eh-nb-title6', link: 'gallery'},     
            ],*/
        recruitUrl:process.env.VUE_APP_CLIENT_URL
        }
  },
  components: {

  },
  methods: {
    ...mapMutations({
          // 'setWebLang': 'jobseekerProfile/setWebLang',
          'setWebLang': 'auth/setWebLang',
      }),
    ...mapActions({
            fetchLanguageList: 'home/fetchLanguageList',        
        }),
        languageList() {
             this.fetchLanguageList().then((response) => {
                this.languageData = response;
            })
            
        },
    languageHandler() {
        this.$store.commit('changeLanguageSlug', this.locale)
    },
    getWebLang(){
          this.setWebLang(this.locale);
      },
    
    closeDeleteModel(){
      this.showList = false
    },
 
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.replace({ name: 'Home' })
      })
    }
  }, 
  computed: {
     ...mapState({
            language: 'language'
         }),
    ...mapGetters({
      user: 'auth/user',
    })
  }, 
  mounted() {
    let vm = this
    this.$store.commit('HANDLE_MAIN_HEADER_BASED_ON_AUTH')
    // handling profile dropdown close on click anywhere
    // document.body.addEventListener('click', function(){vm.isDropped = false}, true);
    this.$store.commit('isAuthenticated', false)
    this.languageList();
    let currentLocale = 'en'
    if (!isEmpty(localStorage.getItem('localforage/jiipa/locale'))) {
      currentLocale = localStorage.getItem('localforage/jiipa/locale').replace(/['"]+/g, '')
  }
  this.locale = currentLocale || 'en';
  this.getWebLang();
  },
};
</script>

<style>
.slide-enter-active {
  transition: 0.7s;
}
.slide-leave-active{
  transition: 0.6s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}

.menu_active.router-link-exact-active{
    color: #059669;
    text-decoration: underline;
}
</style>
